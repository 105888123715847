import React, { useCallback, useMemo } from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import CircleBlur from "@common/CircleBlur/CircleBlur";
import clutchLogo from "@images/clutch-logo.svg";
import clutchTelecommunicationLogo from "@images/clutch-telecommunication.svg";
import goodFirmsLogo from "@images/good-firms.svg";
import wadlineLogo from "@images/wadline-logo.svg";
import upcityLogo from "@images/upcity-logo.svg";
import manifestLogo from "@images/manifest-logo.svg";
import developersLogo from "@images/developers-logo.svg";
import { 
    GLOBAL_APPLICATION_EXTERNAL_LINKS, 
    GLOBAL_APPLICATION_INTERNAL_LINKS 
} from "@links";
import { isPathnameEqualTo } from "../../../../helpers";
import {
    ProudSectionWrapper,
    ProudLogoContainer,
    ProudLogoRow,
    ProudLogo,
} from "./ProudSection.style";
import { LayoutWrapper } from "../../../../styles/CommonStyles";
import { MAX_DEFAULT_BADGES } from "./constants";

const ProudSection = ({customBadges, hideSecondRow, customTitle, withoutTitle}) => {
    const clutch = isPathnameEqualTo(GLOBAL_APPLICATION_INTERNAL_LINKS.TELECOMMUNICATION) 
        || isPathnameEqualTo(GLOBAL_APPLICATION_INTERNAL_LINKS.INTERNET_SERVICE_PROVIDERS) ? clutchTelecommunicationLogo : clutchLogo;

    const findBadgeByIndex = (index) => {
        if(customBadges?.length) {
            return customBadges.find(badge => badge?.index === index)
        }
    }

    const getBadgeLinkByIndex = useCallback((index) => {
        const relevantBadge = findBadgeByIndex(index)

        return relevantBadge?.href
    }, [customBadges])

    const getBadgeImageByIndex = useCallback((index) => {
        const relevantBadge = findBadgeByIndex(index)

        return relevantBadge?.src
    }, [customBadges])

    const hasExtraBadges = useMemo(() => {
        return findBadgeByIndex(MAX_DEFAULT_BADGES - 1)
    }, [])

    return (
        <LayoutWrapper>
        <ProudSectionWrapper>
            {!withoutTitle && (
                <PageHeading heading={customTitle || "We are proud to be"} />
            )}
            <ProudLogoContainer>
                <ProudLogoRow>
                    <ProudLogo
                        target="_blank"
                        href={
                            getBadgeLinkByIndex(0) ||
                            GLOBAL_APPLICATION_EXTERNAL_LINKS.CLUTCH_IMPRESSIT
                        }
                        className="proud-section__image-container"
                        rel="nofollow noreferrer"
                    >
                        <img
                            src={getBadgeImageByIndex(0) || clutch}
                            alt="clutch"
                            className="proud-section__image"
                        />
                    </ProudLogo>
                    <CircleBlur
                        width="527px"
                        left="0"
                        right="0"
                        margin="auto"
                        bottom="0"
                        top="0"
                        height="527px"
                    />
                    <ProudLogo
                        target="_blank"
                        href={
                            getBadgeLinkByIndex(1) ||
                            GLOBAL_APPLICATION_EXTERNAL_LINKS.GOODFIRMS_IMPRESSIT
                        }
                        className="proud-section__image-container"
                        rel="nofollow noreferrer"
                    >
                        <img
                            src={getBadgeImageByIndex(1) || goodFirmsLogo}
                            alt="good-firms"
                            className="proud-section__image proud-section__image-firms"
                        />
                    </ProudLogo>
                    <ProudLogo
                        target="_blank"
                        href={
                            getBadgeLinkByIndex(2) ||
                            GLOBAL_APPLICATION_EXTERNAL_LINKS.WADLINE_IMPRESSIT
                        }
                        className="proud-section__image-container"
                        rel="nofollow noreferrer"
                    >
                        <img
                            src={getBadgeImageByIndex(2) || wadlineLogo}
                            alt="wadline"
                            className="proud-section__image"
                        />
                    </ProudLogo>
                </ProudLogoRow>
                {!hideSecondRow && (
                    <ProudLogoRow>
                        <ProudLogo
                            target="_blank"
                            href={
                                getBadgeLinkByIndex(3) ||
                                GLOBAL_APPLICATION_EXTERNAL_LINKS.UPCITY_IMPRESSIT
                            }
                            className="proud-section__image-container"
                            rel="nofollow noreferrer"
                        >
                            <img
                                src={getBadgeImageByIndex(3) || upcityLogo}
                                alt="upcity"
                                className="proud-section__image"
                            />
                        </ProudLogo>
                        <ProudLogo
                            target="_blank"
                            href={
                                getBadgeLinkByIndex(4) ||
                                GLOBAL_APPLICATION_EXTERNAL_LINKS.THE_MANIFEST_IMPRESSIT
                            }
                            className="proud-section__image-container"
                            rel="nofollow noreferrer"
                        >
                            <img
                                src={getBadgeImageByIndex(4) || manifestLogo}
                                alt="manifest"
                                className="proud-section__image"
                            />
                        </ProudLogo>
                        <ProudLogo
                            target="_blank"
                            href={
                                getBadgeLinkByIndex(5) ||
                                GLOBAL_APPLICATION_EXTERNAL_LINKS.TOP_DEVELOPERS_IMPRESSIT
                            }
                            className="proud-section__image-container"
                            rel="nofollow noreferrer"
                        >
                            <img
                                src={getBadgeImageByIndex(5) || developersLogo}
                                alt="Top Developers"
                                className="proud-section__image"
                            />
                        </ProudLogo>
                    </ProudLogoRow>  
                )}
                {hasExtraBadges ? (
                    <ProudLogoRow>
                        {getBadgeLinkByIndex(6) && (
                            <ProudLogo
                                target="_blank"
                                href={getBadgeLinkByIndex(6)}
                                className="proud-section__image-container"
                                rel="nofollow noreferrer"
                            >
                                <img
                                    src={getBadgeImageByIndex(6)}
                                    alt="extra badge"
                                    className="proud-section__image"
                                />
                            </ProudLogo>
                        )}
                        {getBadgeLinkByIndex(7) && (
                            <ProudLogo
                                target="_blank"
                                href={getBadgeLinkByIndex(7)}
                                className="proud-section__image-container"
                                rel="nofollow noreferrer"
                            >
                                <img
                                    src={getBadgeImageByIndex(7)}
                                    alt="extra badge"
                                    className="proud-section__image"
                                />
                            </ProudLogo>
                        )}
                        {getBadgeLinkByIndex(8) && (
                            <ProudLogo
                                target="_blank"
                                href={getBadgeLinkByIndex(8)}
                                className="proud-section__image-container"
                                rel="nofollow noreferrer"
                            >
                                <img
                                    src={getBadgeImageByIndex(8)}
                                    alt="extra badge"
                                    className="proud-section__image"
                                />
                            </ProudLogo>
                        )}
                    </ProudLogoRow>
                ) : null}
            </ProudLogoContainer>
        </ProudSectionWrapper>
    </LayoutWrapper>
    )
}

export default ProudSection;
